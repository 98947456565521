.bx {
  &--toggle {
    &__switch {
      margin-right: $carbon--spacing-03;
      margin-top: 0 !important;
    }
    &-input {
      &__label {
        flex-direction: row-reverse;
        align-items: center;
      }
    }
  }
}
.toggle-custom{
  position: relative;
  input{
    left: 0;
  }
}