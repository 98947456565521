@import "styles/vars.scss";

.bx--side-nav__submenu-title {
  font-weight: 400;
}

.bx--side-nav__navigation {
  .bx--side-nav {
    &__link {
      padding: 8px 16px 8px 15px;
      font-weight: 400;
      border-left: 3px solid transparent;
      text-decoration: none;
      outline: none;

      &_active {
        background: $hover-secondary;
        border-color: $brand-color;
        font-weight: 600;
      }

      &:focus {
        outline: 2px solid $brand-color;
      }

      .bx--side-nav__link-text {
        color: $text-primary;
      }
    }

    &__menu-item {
      .bx--side-nav__link {
        border-left: 3px solid transparent;
        text-decoration: none;

        > .bx--side-nav__link-text {
          color: $text-secondary;
        }

        &:not(&--current):not([aria-current=page]) {
          &:hover {
            background-color: $hover-primary;

            > .bx--side-nav__link-text {
              color: $text-secondary;
            }
          }
        }
      }

      &_active {
        .bx--side-nav__link {
          background: $hover-secondary;
          border-color: $brand-color;
          font-weight: 600;

          > .bx--side-nav__link-text {
            color: $text-secondary;
          }
        }
      }
    }

    &__item {
      &:not(&--active) {
        > .bx--side-nav__link {
          &:hover {
            background-color: $hover-primary;
            color: $text-primary;
          }
        }
      }
    }

    &__icon {
      margin-right: 16px;

      > svg {
        fill: $text-primary;
      }
    }

    &__submenu {
      height: 36px;
      color: $text-primary;
      font-weight: 400;
      outline: none;

      &:hover {
        background-color: $hover-primary;
      }
    }
  }
}
.bx--side-nav__overlay{
  @media screen and (max-width: $mobile-menu-breakpoint){
    //display: none;
  }
}
