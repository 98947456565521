@mixin button-theme-custom(
  $font-color,
  $font-color-active,
  $border-color,
  $bg-color,
  $hover-bg-color,
  $active-color,
  $focus-bg-color,
  $focus-border-color,
) {
  border-color: $border-color;
  background-color: $bg-color;
  color: $font-color;

  &:hover {
    border-color: $hover-bg-color;
    background-color: $hover-bg-color;
    color: $font-color;
  }

  &:focus {
    border-color: $focus-border-color;
    background-color: $focus-bg-color;
    box-shadow: inset 0 0 0 1px $focus-border-color,
                inset 0 0 0 2px #fff;
    color: $font-color;
  }

  &:active {
    border-color: $active-color;
    background-color: $active-color;
    color: $font-color-active;
  }

  &:disabled {
    border-color: transparent;
    background-color: $bg-color;
    color: $font-color;

    &:hover,
    &:focus {
      background-color: $bg-color;
      color: $font-color;
    }

    &:not(.bx--btn--loading) {
      opacity: .3;
    }
  }

  .bx--loading {
    margin: 0 auto;

    &__stroke {
      stroke: $font-color;
    }

    &__background {
      stroke: transparent;
    }
  }

  &.bx--btn--loading {
    padding: calc(0.875rem - 3px) 15px;

    .bx--btn__icon {
      display: none;
    }
  }
}

@mixin placehoder-color($color) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
}
