@import "./../vars.scss";

.multi-select__required-error{
  border: 2px solid $brand-error-color;
  &__text{
    color: $brand-error-color;
    font-size: 12px;
    margin-top: 4px;
  }
}

.multi-select__twoColumns{
  display: grid;
  grid-template: 
    "leftColumn rightColumn" 
    "bottomCounter bottomCounter";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 307px 36px;
  gap: 1px;
  
  .multi-select{
    grid-area: leftColumn;
    &___right-column__remover{
      display: flex;
      align-items: center;
      span{
        margin-left: 10px;
      }
    }

    &___right-column__counter{
      line-height: 26px;
    }
  }

  .multi-select___bottom-counter{
    grid-area: bottomCounter;
    background-color: $background-default;
    padding: 10px;
  }

  .multi-select___right-column{
    display: flex;
    flex-direction: column;
    background-color: $background-default;
    grid-area: rightColumn;
    
    &__counter{
      border-bottom: 1px solid $border-primary;
      padding: 10px 10px 10px 10px;
    }
    &__remover{
      border-bottom: 1px solid $border-primary;
    }

    ul{
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 260px;
      li{
        display: flex;
        align-items: center;
        padding: 10px;
        height: 35px;
        cursor: pointer;
        &:first-child{
          margin-bottom: 7px;
          padding: 10px 10px 10px 10px;
          height:36px;
          svg{
            margin-left: unset;
          }
        }
        svg{
          display: unset;
          margin-left: auto;
        }
      }
    }
  }
  
}
