@import "styles/vars.scss";

.bx--header {
  a.bx--header__name {
    padding: 0;
    pointer-events: none;
    @media screen and (max-width: $mobile-menu-breakpoint) {
      margin-left: 24px
    }
  }
  a.bx--header__menu-title {
    font-weight: 600;
  }
  &__nav {
    &:before {
      content: none;
    }
  }

  .bx--header {
    &__menu-item {
      transition: all .25s ease-in;
      color: $ui-01 !important;

      &:hover {
        background: $brand-color-blue-active;
      }

      &:active {
        background: $brand-color-blue-active;
      }

      &:focus {
        border-color: transparent;
      }
    }
    &__action {
      &:hover {
        background: $brand-color-blue-active;
      }
      &--active {
        border-color: $brand-color-blue-active;
      }
    }
    &__menu-title[aria-expanded=true] {
      background: $brand-color-blue-active;
    }
    &__menu-title[aria-expanded=true] + .bx--header__menu {
      width: 304px;
      background: $background-default;
      left: -160px;
      color: $text-primary;
      box-shadow: $dropdown-box-shadow;
      li.bx--side-nav__divider {
        margin: 0;
      }
      .info-user--logo {
        .bx--header__menu-item {
          &__icon {
            margin-right: 0;
          }
        }
      }
      .bx--header__menu-item {
        color: $text-primary !important;
        &:hover {
          background: $ui-01;
        }
        &__icon {
          vertical-align: middle;
          margin-right: $carbon--spacing-03;
        }
      }
    }
  }
}