.info-user {
  display: flex;
  align-items: center;
  padding: 22px 16px;
  font-size: 16px;
  &--wrap {
    display: flex;
    flex-direction: column;
  }
  &--username {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    font-weight: 600;
    color: $background-secondary;
  }
  &--email {
    font-size: 14px;
    line-height: 20px;
    color: $text-secondary;
    font-weight: 400;
  }
  &--logo {
    background: $hover-primary;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $carbon--spacing-03;
    height: 40px;
    border-radius: 50%;
  }
}