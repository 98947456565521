.search-with-field-select__container{
  display: grid;
  grid-template-columns: 2fr 1fr auto;

  margin-right: auto;
  width:100%;
  .bx--search{
    flex-grow: 3;
    input{
      padding: 14px 45px 14px 16px;
    }
  }
  .bx--dropdown__wrapper{
    margin-left: 1px;
    flex-grow: 1;

  }
}