.bx--breadcrumb {
  margin-bottom: 64px;
  position: absolute;
  top: 0;
  left: 0;
  &-item {
    .bx--link:visited {
      color: $brand-color;
      &:hover {
        color: $brand-color;
      }
    }
    &:after {
      font-weight: 400;
      font-size: 12px;
      color: $copyright-color;
    }
    .active {
      cursor: pointer;
      color: $brand-color;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

  }
}

.crumb {
  pointer-events: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $copyright-color;
}