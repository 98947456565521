@import '~carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import '~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import '~carbon-components/scss/globals/scss/typography.scss';
@import '~carbon-components/scss/globals/scss/vars.scss';
@import '~@carbon/themes/scss/themes';
@import "general";
@import "./vars";
@import "./mixins";
@import "atoms/index";
@import "molecules/index";
@import "organisms/index";
@import "pages/index";
@include carbon--theme($carbon--theme--g10);
$interactive-01: #0077D4;
.App-header {
  background: #0077D4;
}/*.bx--header {
  background: #0077D4;
}*/

html, body, #root {
  height: 100%;
}
body {
  font-family: $fontPrimary;
}
.bx {
  &--tag--interactive:focus {
    box-shadow: none;
  }
  &--content{
    padding: 1.5rem;
    @media screen and (max-width: $mobile-menu-breakpoint){
      min-height: 100%;
    }
    .bx--grid{
      max-width: unset;
    }
  }
  &--grid {
    padding: 0;
  }
  &--header {
    background: $interactive-01;
    border-color: $interactive-01;
    color: $ui-01;
      &__menu-item {
 /*       color: $ui-01 !important;*/
        //&:hover {
        //  background-color: $link-02 !important;
        //}
    }
  }
}
.bx--checkbox, .bx--radio-button, .bx--visually-hidden, .bx--toggle-input {
  left: -999px;
}

.bx--toggle-input ~ .bx--toggle-input__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $text-primary;
}

.bx--toggle-input:checked ~ .bx--toggle-input__label {
  .bx--toggle__switch {
    &:before {
      background: $background-toggle;

    }
  }
}

