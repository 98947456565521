.bx--header {
    .header-menu-content {
      &--chevron {
        display: flex;
        margin-left: 13px;
      }
      &--name {
        margin-left: 10px;
      }
    }
  .bx--header {
    &__menu-title[aria-expanded=true] {
      .header-menu-content--chevron {
        svg {
          transform: rotate(180deg);
          transition: transform 110ms;
        }
      }
    }
    &__menu-title[aria-expanded=false] {
      .header-menu-content--chevron {
        svg {
          transition: transform 110ms;
        }
      }
    }
  }
}