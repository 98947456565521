.verified {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 700;
  }
  &__subtitle {
    color: $text-secondary
  }
  &__icon {
    svg {
      height: 56px;
      width: 56px;
      fill: $brand-color-blue-hover;
    }
  }
}