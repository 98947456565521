.bx--modal {
  &-header {
    padding: 1rem 3rem 0 1.5rem;

    &__heading {
      &__scale {
        position: absolute;
        top: 15px;
        right: 50px;
        cursor: pointer;
      }
    }
    &--deactivating-user-modal{
      font-weight: 700;
    }
  }
  &--full-screen {
    width: 100%;
    height: 100%;
    .bx--modal-container {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    padding: 0;
    overflow: hidden;
    margin: 0;
    &__body {
      height: calc(100% - 70px);
      overflow: auto;
      padding: $carbon--spacing-03 $carbon--spacing-06 0;
    }
    &__footer {
      padding: $carbon--spacing-06;
      border-top: 1px solid $border-primary;
      display: grid;
      justify-content: flex-end;
      grid-template-columns: max-content max-content;
      grid-gap: 10px;
      align-content: center;
      height: 70px;
    }
  }

  &-close {
    &:focus {
      border-color: transparent;
    }
  }
  .deactivating-user-modal--button--icon{
    position: absolute;
    right: 15px;
    font-size: 24px;
  }
}
