.description {
  padding: 2px;
  &__title {
    color: $text-primary;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__value {
    color: $text-secondary;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}