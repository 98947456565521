.bx {
  &--form {
    &__item {
      margin: $carbon--spacing-02 0;
    }
  }

  &--label {
    margin-bottom: $carbon--spacing-01;
    display: inline;
  }

  &--list-box {
    &__wrapper {
      &--inline {
      }
      display: block;
    }
  }
}