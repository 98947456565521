.fields-array {
  width: 100%;
  margin: $carbon--spacing-03 0;

  .field-with-remove {
    display: inline-flex;
    width: 100%;
    &__btn {
      margin-left: 20px;
      padding: 13px 14px;
      background: rgba($danger-btn, 0.1);
      border-radius: 48px;
      margin-top: 22px;
      cursor: pointer;
      svg {
        fill: $danger-btn;
      }
      &:hover {
        svg {
          transform: scale(1.2);
          transition: all 0.2s;
        }
        background: rgba($danger-btn, 0.14);
      }
    }
  }

  &__top {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    margin-bottom: 5px;
    align-items: center;

    &__divider {
      width: 38px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 1px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $ui-03;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $text-primary;
    }

    &__add {
      color: $secondary-btn;
      cursor: pointer;
    }
  }
}