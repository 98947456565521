.bx--btn {
  &--primary {
    @include button-theme-custom(
      $btn-text-main-color,
      $btn-text-main-color,
      $primary-btn-border-color,
      $primary-btn,
      $primary-btn-hover,
      $primary-btn-active,
      $primary-btn,
      $primary-btn-focus-border-color
    );
  }

  &--secondary {
    @include button-theme-custom(
      $btn-text-secondary-color,
      $btn-text-secondary-color,
      $secondary-btn-border-color,
      $secondary-btn,
      $secondary-btn-hover,
      $secondary-btn-active,
      $secondary-btn,
      $secondary-btn-focus-border-color
    );
  }

  &--tertiary {
    @include button-theme-custom(
      $btn-text-main-color,
      $btn-text-secondary-color,
      $tertiary-btn-border-color,
      $tertiary-btn,
      $tertiary-btn-hover,
      $tertiary-btn-active,
      $tertiary-btn,
      $tertiary-btn-focus-border-color
    );
  }

  &--danger {
    @include button-theme-custom(
      $btn-text-secondary-color,
      $btn-text-secondary-color,
      $danger-btn-border-color,
      $danger-btn,
      $danger-btn-hover,
      $danger-btn-active,
      $danger-btn,
      $danger-btn-focus-border-color
    );
  }

  &.bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
    &:focus {
      outline: none;
    }
  }
}
