$pending-color: #C7EBFF;
$error-color: #FFB9BC;
$waiting-color: #FFE27B;
$approved-color: #A7F0BA;
$active-color: #A7F0BA;
$declined-color: #CFD7DD;
$default-color: #f2f3f6;
$info-color: $brand-color;
$deactivated-color: #E0E0E0;

.info-tag-secondary-wrap {
  display: flex;
  align-items: center;
}
.info-secondary-tag {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
  color: $text-primary;
  font-size: 14px;
  &-label {
    text-transform: capitalize;
  }
  &--deactivated {
    background-color: $deactivated-color;
  }
}

.info-secondary-tag, .info-tag {
  &--reverse {
    color: $text-04;
  }
  &--fit-content {
    width: fit-content;
  }
  &--pending {
    background-color: $pending-color;
  }
  &--error {
    background-color: $error-color;
  }
  &--waiting {
    background-color: $waiting-color;
  }
  &--approved {
    background-color: $approved-color;
  }
  &--active {
    background-color: $approved-color;
  }
  &--declined {
    background-color: $declined-color;
  }
  &--declined {
    background-color: $declined-color;
  }
  &--default {
    background-color: $default-color;
  }
  &--info {
    background-color: $info-color;
  }
}

.info-tag {
  min-width: fit-content;
  border-radius: 30px;
  color: $text-primary;
  font-size: 14px;
  span{
    text-align: center;
  }
}