$brand-color: #0077D4;
$brand-color-blue-hover: #058DF6;
$brand-color-blue-active: #00579B;
$brand-success-color: #A7F0BA;
$brand-error-color: #DA1E28;

$hover-primary: #F3F5F6;
$hover-secondary: #E5E9EB;

$background-primary: #F0F3F5;
$background-secondary: #252C32;
$background-table: #e5e9eb;
$background-default: #fff;
$background-toggle: #6ACF84;
$background-dropdown: #E5E5E5;

$border-primary: #E5E9EB;

$text-primary: #252C32;
$text-secondary: #5B6871;
$text-inverted: #fff;
$text-mobile-primary: #101840;
$text-mobile-secondary: #474D66;

$copyright-color: #6C8593;

$icon-primary: $text-primary;

$btn-text-main-color: $text-primary;
$btn-text-secondary-color: #fff;

$primary-btn: $brand-success-color;
$primary-btn-hover: #AFFFC4;
$primary-btn-active: #92D4A3;
$primary-btn-border-color: $primary-btn;
$primary-btn-focus-border-color: $primary-btn;

$secondary-btn: #0077D4;
$secondary-btn-hover: #058DF6;
$secondary-btn-active: #0269BA;
$secondary-btn-border-color: $secondary-btn;
$secondary-btn-focus-border-color: $secondary-btn;

$tertiary-btn: #E5E9EB;
$tertiary-btn-hover: #CFD7DD;
$tertiary-btn-active: #6C8593;
$tertiary-btn-border-color: $tertiary-btn;
$tertiary-btn-focus-border-color: $brand-color;

$danger-btn: $brand-error-color;
$danger-btn-hover: #F0333D;
$danger-btn-active: #B01119;
$danger-btn-border-color: $danger-btn;
$danger-btn-focus-border-color: $danger-btn;

$input-border-color: #829CAB;
$input-hover-border-color: $brand-color;
$input-background: #fff;
$input-background-inverted: #F3F4F5;
$input-background-disabled: #C6C6C6;
$placeholder-color: #9FAAB1;

$search-btn: #fff;
$search-border-color: $input-border-color;
$search-hover-border-color: $input-hover-border-color;

$dropdown-separator-color: rgba(229, 233, 235, 1);

$dropdown-primary-background: #fff;
$dropdown-box-shadow: 0px 0px 5px rgba(54, 83, 99, 0.1), 0px 25px 30px rgba(54, 83, 99, 0.1);

$fontPrimary: "Inter", sans-serif;

$custom-checkbox-background-hover: #095DC8;
$custom-checkbox-background: #0E75FF;

$date-range-box-shadow: 0px 0px 5px rgba(54, 83, 99, 0.1), 0px 25px 30px rgba(54, 83, 99, 0.1);

$mobile-menu-breakpoint: 671px;
