.bx--accordion {
  background: $background-default;
  &__heading {
    padding: 0 $carbon--spacing-03;
    align-items: center;
  }

  &__content {
    padding: $carbon--spacing-05;
  }

  &__title {
    margin: 0;
  }

  &__item {
    border: none;

    &:last-child {
      border: none;
    }
  }

}