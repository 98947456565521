.description-list {
  display: grid;
  grid-template-columns: repeat(auto-fill,50%);
  grid-row-gap: 10px;
  word-break: break-word;

  & > .description {
   /* border-bottom: 1px solid $ui-01;*/
    padding: $carbon--spacing-02;
  }
}