.expanded-row {
  display: grid;
  grid-template-columns: repeat(auto-fill,250px);
  grid-row-gap: 10px;
  padding: 5px 10px;
  &--wrap {
   /* border-left: 3px solid $brand-color;*/
    box-shadow: -1px -5px 4px rgb(16 24 64 / 8%);
    & > div {
      background: #F4F6FA;
      height: 100%;
    }
  }
}