.bx--search-wrapper,
.bx--text-input-wrapper {
  display: flex;

  &:hover {
    .bx--search,
    .bx--text-input {
      &-input:not(:disabled),
      &-button:not(:disabled) {
        border-bottom: 1px solid $input-hover-border-color;
      }
    }
  }

  &:focus-within {
    .bx--search-input,
    .bx--text-input {
      border-bottom: 1px solid $input-hover-border-color;
    }
  }


  .bx--text {
    &-input:not(:disabled) {
      &:hover {
        border-bottom: 1px solid $input-hover-border-color;
      }
    }
  }

  .bx--search,
  .bx--text {
    &:focus-within {
     + .bx--search-button {
       border-bottom: 1px solid $input-hover-border-color;
     }
    }

    &-input {
      padding: 14px 45px 14px 16px;
      border-bottom: 1px solid $input-border-color;
      color: $text-primary;
      @include placehoder-color($placeholder-color);

      &--inverted {
        background-color: $input-background-inverted;
      }

      &:focus,
      &:active {
        &:not(:disabled) {
          outline: 2px solid $input-hover-border-color;
          background-color: $input-background;
        }
      }

      &:focus {
        ~ .bx--search-close {
          &:hover {
            outline: 2px solid $input-hover-border-color;
            background-color: $hover-primary;
          }
        }
      }

      &:disabled {
        border-color: transparent;
        color: $input-background-disabled;
      }
    }

    &-button {
      @include button-theme-custom(
        $icon-primary,
        $icon-primary,
        $search-btn,
        $search-btn,
        $search-btn,
        $search-btn,
        $search-btn,
        $search-btn
      );
      border-bottom: 1px solid $search-border-color;
      box-shadow: none;

      &.bx--btn.bx--btn--icon-only {
        &:active:not([disabled]),
        &:focus{
          outline: none;
          border-top-color: transparent;
          border-bottom-color: $input-hover-border-color;
        }
      }

      &:disabled {
        border-bottom: 1px solid $search-border-color;
      }
    }

    &-close {
      &:before {
        content: none;
      }

      &:hover {
        background-color: $hover-primary;
        border-bottom-color: $input-hover-border-color;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .bx--label {
    color: $text-secondary;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
