.dots-menu {
  &__item {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 7px;
    &--danger {
      color: $danger-btn;
      svg {
        fill: $danger-btn;
      }
    }
    &--secondary {
      color: $secondary-btn;
      svg {
        fill: $secondary-btn;
      }
    }
  }
}