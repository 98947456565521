.auth {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $background-default;

  &__container {
    background-color: $background-default;
    padding: 80px 0 0;


    &.reset-password--success {
      .auth__subtitle {
        margin-bottom: 40px;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    padding: 55px;
    //border: 1px solid $border-primary;
  }

  &__logo {
    width: 56px;
    height: 56px;
    margin: 0 auto 16px;
  }

  &__title {
    display: flex;
    margin-bottom: 40px;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 700;
    text-align: center;

    &.has-subtitle {
      margin-bottom: 12px;
    }

    &-text {
      flex-grow: 1;
    }

    .back-btn {
      display: block;
      padding-right: 5px;
      cursor: pointer;
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    font-size: 1rem;
    color: $text-secondary;
    line-height: 1.5rem;
    text-align: center;
  }

  &__link-wrapper {
    margin-top: 24px;
    text-align: center;

    a {
      line-height: 18px;
    }
  }

  &__footer-wrapper {
    margin-top: 24px;
    color: $text-secondary;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &__copyright {
    padding-bottom: 40px;
    background-color: $background-default;
    color: $copyright-color;
    text-align: center;
  }

  a {
    color: $brand-color;
    text-decoration: none;
  }

  .bx--content {
    flex-grow: 1;
    background-color: $background-default;
  }

  .bx--grid {
    padding-right: 1rem;
  }

  .bx--form-item {
    margin-bottom: 16px;

    &.bx--checkbox-wrapper {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  .bx--btn[type="submit"] {
    width: 100%;
    max-width: none;
    margin-top: 16px;
  }

  .tfa-enable {
    font-size: 0.875rem;
    line-height: 1.25rem;

    &__title {
      margin-top: -4px;
      margin-bottom: 16px;
      color: $text-primary;
      font-weight: 600;
    }

    &__step {
      margin-bottom: 16px;
      color: $text-secondary;

      &-hint {
        color: $text-secondary;
      }

      &-subhint {
        .subhint-text {
          margin-bottom: 8px;
        }
        .subhint-code {
          color: $text-primary;
          font-weight: 600;
        }
      }

      &-content {
        display: flex;
        align-items: center;

        svg {
          width: 158px;
          height: 158px;
          margin: 5px 8px -8px -6px;
        }
      }
    }
  }
  &__description {
    margin-bottom: $carbon--spacing-03;
    &__title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: $carbon--spacing-06;
    }
    .bx--list--ordered {
      margin-left: $carbon--spacing-06;
      .bx--list__item {
        color: $text-02;
      }
    }
    &__qr {
      img {
        width: 146px;
        height: 146px;
        border: 1px solid $border-primary;
      }
      &__text-code {
        font-weight: 600;
      }
    }
  }
}

.auth--bg {
  background-image: url(../../images/userBg.jpg);
  background-size: cover;
  overflow: auto;
  .bx--content {
    background-color: transparent;
    .auth__container {
      background-color: transparent;
    }
    .auth__content {
      background-color: $background-default
    }
  }
  .auth__copyright {
    background-color: transparent;
  }
}
