.tx-dec-none {
  text-decoration: none;
}
.mv-3 {
  margin: $carbon--spacing-03 0;
}
.mv-2 {
  margin: $carbon--spacing-02 0;
}
.mtop-2 {
  margin-top: $carbon--spacing-02;
}
.tx-right {
  text-align: right;
}

.maxWidthContent {
  max-width: fit-content;
}