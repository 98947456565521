.no-data {
  display: grid;
  justify-content: center;
  justify-items: center;

  &__icon-wrap {
    position: relative;
    width: 116px;
    height: 116px;
    border-radius: 100%;
    background: linear-gradient(0deg, $background-default 0%, rgba(255, 255, 255, 0) 100%);
    svg {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 30px;
      left: 27px;
    }
  }
  &__text {
    color: $text-03;
    margin: $carbon--spacing-05 0;
  }
}