.bx--loading {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 2;
  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;

    &.form-overlay {
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      justify-content: center;
      background-color: $background-default;
      &__opacity {
        &--100 {
          background: transparentize($background-default, 0);
        }
        &--80 {
          background: transparentize($background-default, .8);
        }
        &--50 {
          background: transparentize($background-default, .5);
        }
        &--0 {
          background: transparentize($background-default, 1);
        }
      }

      .bx--loading {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

  }

  &__stroke {
    stroke: $brand-color;
  }

  &__text {
    margin-top: 12px;
    color: $text-secondary;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: .32px;
  }
}
