.bx--tabs {
  display: flex;

  &--scrollable {
    .bx--tabs--scrollable {
      &__nav-link {
        overflow: auto;
        text-overflow: initial;
        &:focus {
          outline: 2px solid $brand-color;
        }
      }

      &__nav-item {
        .bx--tabs--scrollable__nav-link {
          padding: 18px 1rem 1rem;
          color: $brand-color;
        }

        &:hover {
          .bx--tabs--scrollable__nav-link {
            //color: $brand-color;
          }
        }
      }

      &__nav-item--selected {
        .bx--tabs--scrollable__nav-link {
          border-bottom: 2px solid $brand-color;
          color: $text-primary;
          line-height: 20px;

          &:focus {
            line-height: 20px;
          }
        }
      }
    }

    .bx--tab--overflow-nav-button {
      &:focus {
        outline: none;
      }
    }
  }
}

.bx--tab {
  &-content {
    &:focus {
      outline: none;
    }
  }
}
