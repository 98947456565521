.filter-tag {
  &__label {
    vertical-align: middle;
    display: inline-block;
  }
  &__value {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
  }
 &__close {
   cursor: pointer;
   margin-left: 5px;
   vertical-align: middle;
   &-hov {
     &:hover {
       color: $text-03;
     }
   }

 }
  &--danger {
    background: transparent;
    color: red;
  }
}