.checkbox-panel {
  display: grid;
  grid-template-columns: calc(100% - 30px) 30px;
  align-items: flex-start;
  background: $ui-02;
  height: 75px;
  padding: $carbon--spacing-05;
  &__info {
    &__title {
        color: $text-01;
    }
    &__description {
      color: $text-02;
    }
  }
}