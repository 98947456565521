.card {
  &__body {
    &--without-filter {
      margin-top: 30px;
    }
  }
  &__head {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    margin-bottom: $carbon--spacing-03;

    &.card-with-extra{
      height: 48px;
    }
    a {
      color: $text-01;
      svg {
        margin-right: 15px;
      }
    }

    &__title {
      font-size: 28px;
      font-weight: bold;
    }

    &__subtitle {
      font-size: 12px;
      font-weight: 400;
      color: $text-03;
      margin-top: $carbon--spacing-03;
      svg {
        vertical-align: middle;
        margin-left: $carbon--spacing-03;
        color: $text-01;
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__extra {
      position: absolute;
      right: 0;
      top: 0;

      > .bx--btn {
        min-width: 195px;
        margin-left: 16px;
        padding-right: 50px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.card-wrap {
  padding-top: 30px;
}